import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Entrepreneurship"
const PAGE_NAME = "entrepreneurship"
const EntrepreneurshipPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-6">
            <p>
              This program aims to deliver services and business assistances to
              youth entrepreneurial initiatives such as:{" "}
            </p>
            <ul>
              <li>Help with business basics</li>
              <li>Networking activities</li>
              <li>Marketing assistance</li>
              <li>High-speed Internet access</li>
              <li>Help with accounting/financial management</li>
              <li>Access to bank loans, loan funds and guarantee programs</li>
              <li>Links to strategic partners</li>
              <li>Access to angel investors or venture capital</li>
              <li>Comprehensive business training programs</li>
              <li>Advisory boards and mentors</li>
              <li>Management team identification</li>
              <li>Technology commercialization assistance</li>
              <li>Help with regulatory compliance</li>
              <li>Intellectual property management</li>
            </ul>
          </div>

          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image57"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default EntrepreneurshipPage
